import React from 'react';

import pageStyle from '../assets/css/page.module.css';
import style from '../assets/css/main.module.css';
import Menu from '../components/menu';
import Footer from '../components/footer';
import HeroPage from '../components/hero.page';
import Hamburger from '../components/hamburger';

import SEO from '../components/seo';

import imgHero from '../assets/img/heroes/vie_privee.jpg';

export default () => (
  <div id="top">
    <Menu />
    <SEO
      title="Politique de confidentialité | Agence Digitale | MDS Digital Agency"
      description="Grâce à cette déclaration de confidentialité, nous vous informons de la manière dont nous, MDS Digital Agency, traitons et utilisons vos données personnelles."
      keywords="confidentialite"
    />
    <div className={style.menu_mobile}>
      <Hamburger />
    </div>
    <HeroPage img={imgHero} />

    <article className={pageStyle.article} style={{ paddingBottom: '50px' }}>
      <h2>Politique de confidentialité</h2>
      <span className={pageStyle.privacy_subtitle}>Droits de propriété intellectuelle</span>

      <p>
        Le contenu de ce site, y compris les marques, logos, dessins, données, noms de produits ou
        d’entreprises, textes, images, etc. est protégé par des droits de propriété intellectuelle
        appartenant à MDS Digital Agency ou à des tiers titulaires de ces droits.
      </p>
      <span className={pageStyle.privacy_subtitle}>Limite de responsabilité</span>
      <p>
        Les informations contenues sur ce site web sont de nature générale. Elles ne sont pas
        adaptées à des circonstances personnelles ou spécifiques et ne peuvent donc être considérées
        comme un conseil personnel, professionnel ou juridique vous étant destiné.
      </p>
      <p>
        MDS Digital Agency consent de gros efforts pour que les informations mises à disposition
        soient complètes, correctes, exhaustives et à jour. Malgré ses efforts, des erreurs peuvent
        figurer dans les informations mises à disposition. Si les informations diffusées sur le site
        comportent des erreurs ou si certaines informations sont indisponibles sur ou via le site,
        MDS Digital Agency mettra tout en œuvre pour rectifier la situation.
      </p>

      <p>
        MDS Digital Agency ne peut toutefois pas être tenue responsable de dommages directs ou
        indirects résultant de l'utilisation des informations mises à disposition sur le site. Si
        vous constatez des erreurs dans les informations mises à disposition sur le site, vous
        pouvez contacter le gestionnaire du site.
      </p>
      <p>
        Le contenu du site (y compris les liens) peut être adapté, modifié ou complété à tout moment
        sans avertissement ni communication aucune. MDS Digital Agency n’octroie aucune garantie de
        bon fonctionnement du site web et ne peut en aucune manière être tenue responsable d’un
        dysfonctionnement du site, d’une indisponibilité provisoire du site ou de toute forme de
        dommages, directs ou indirects, pouvant résulter de l’accès au site et de l’usage du site.
        En aucun cas, MDS Digital Agency ne pourra être tenue pour responsable envers quiconque,
        d'une manière directe, indirecte, spéciale ou autre, des dommages qui pourraient résulter de
        l'usage de ce site ou d'un autre, en raison notamment des connexions ou des liens
        hypertextes, incluant, sans limitation, toute perte, interruption du travail, détérioration
        de programmes ou de données sur le système informatique, le matériel, les logiciels, etc. de
        l’utilisateur.
      </p>
      <p>
        Le site web peut contenir des liens hypertextes vers des sites ou pages web de tiers ou y
        faire référence de manière indirecte. La présence de liens vers ces sites ou pages web ne
        signifie nullement une approbation implicite du contenu de ces sites ou pages. MDS Digital
        Agency déclare explicitement qu’elle n’a aucune autorité sur le contenu ou sur les autres
        caractéristiques de ces sites ou pages et ne peut en aucun cas être tenue responsable du
        contenu ou des caractéristiques desdits sites ou pages web, ni de dommages pouvant résulter
        de leur utilisation.
      </p>
      <span className={pageStyle.privacy_subtitle}>Droit applicable et tribunaux compétents</span>
      <p>
        Ce site est régi par le droit belge en vigueur. Seuls les tribunaux de l’arrondissement de
        MONS sont compétents en cas de litige.
      </p>
      <span className={pageStyle.privacy_subtitle}>Politique de respect de la vie privée</span>
      <p>
        Cette « Déclaration de confidentialité » régit le traitement de vos données à caractère
        personnel par la personne responsable du traitement :{' '}
        <a href="mailto:info@mds-digitalagency.be">Mathieu De Smet</a>.
      </p>
      <p>
        Lisez attentivement cette déclaration de confidentialité, car elle contient des informations
        essentielles sur le traitement de vos données personnelles et les cookies utilisés. En
        fournissant vos données personnelles sur le site web MDS Digital Agency et en utilisant les
        adresses e-mail indiquées sur ce site, vous déclarez avoir pris connaissance de cette
        déclaration de confidentialité et l'avoir expressément acceptée de même que le traitement.
      </p>
      <i>Données à caractère personnel</i>
      <p>
        Cette déclaration de confidentialité concerne vos données à caractère personnel. Le RGPD
        définit ces données comme « toute information se rapportant à une personne physique
        identifiée ou identifiable (aussi appelée « personne concernée »). Est appelée « personne
        physique identifiable », une personne physique qui peut être identifiée, directement ou
        indirectement, notamment par référence à un identifiant, tel qu'un nom, un numéro
        d'identification, des données de localisation, un identifiant en ligne, ou à un (ou
        plusieurs) éléments spécifiques propres à son identité physique, physiologique, génétique,
        psychique, économique, culturelle ou sociale. »
      </p>
      <i>Finalités du traitement</i>
      <p>
        MDS Digital Agency recueille et traite les données à caractère personnel des clients en vue
        de la gestion des clients. De plus, des données à caractère personnel sont collectées à
        travers des cookies afin d'améliorer et optimiser l'utilisation du site et des statistiques
        anonymes.
      </p>
      <i>Base(s) juridique(s) du traitement</i>
      <p>
        Les données personnelles seront traitées via le site web pour diverses raisons juridiques,
        conformément à l'article 6.1 du Règlement général sur la protection des données à caractère
        personnel 2016/679 du 27 avril 2016. Si l'on n'utilise qu'un formulaire de contact et une
        adresse électronique, MDS Digital Agency fonde sa décision sur l'autorisation.
      </p>
      <i>Durée de conservation</i>
      <p>
        Les données à caractère personnel traitées en vue de la gestion des clients seront
        conservées le temps qu’il faut pour satisfaire les obligations légales (entre autres en ce
        qui concerne la comptabilité). Les données personnelles recueillies au moyen de cookies sont
        conservées pendant la session ou pour une durée plus longue si cela est nécessaire pour le
        fonctionnement du site. Lorsque les données sont conservées plus longtemps que la session,
        celles-ci sont anonymisées.
      </p>
      <i>
        Droit d’accès, de rectification, d’effacement, de limitation, d’opposition et de cessibilité
        des données à caractère personnel
      </i>
      <p>
        Le client a en tout temps un droit d’accès à ses données à caractère personnel. Il a le
        droit de les rectifier ou de les faire rectifier si elles s’avèrent être inexactes, de les
        faire effacer, de limiter leur traitement ou de s’opposer au traitement des données le
        concernant sur la base de l’article 6.1 (e) ou (f), y compris le profilage sur la base de
        ces dispositions. Le client a en outre le droit de demander qu’une copie (sous une forme
        structurée, courante et lisible par machine) de ses données à caractère personnel soient
        envoyées à une autre société.
      </p>
      <p>
        Pour exercer lesdits droits, le client est prié:
        <ul>
          <li>de corriger lui-même les paramètres de son compte client ; et / ou</li>
          <li>
            d’envoyer un e-mail à l’adresse suivante :{' '}
            <a href="mailto:info@mds-digitalagency.be">info@mds-digitalagency.be</a>
          </li>
        </ul>
      </p>
      <i>Marketing direct</i>
      <p>
        Le client a le droit de s’opposer gratuitement à tout traitement de ses données à caractère
        personnel à des fins de marketing direct.
      </p>
      <i>Plaintes</i>
      <p>
        Le client a le droit d’introduire une plainte auprès de l’agence MDS Digital Agency. En
        outre, il a le droit d’introduire une plainte auprès d’une autorité de contrôle. Pour la
        Belgique, c’est l’Autorité de Protection des Données - rue de la Presse 35, 1000 Brussel.
      </p>
    </article>

    <Footer />
  </div>
);
